// GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Oswald', sans-serif;

    overflow-x:hidden;
  }


  button {
    font-family: 'Oswald', sans-serif;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: black;
    }
  }



  /* Global text style */
  p, span, h1, h2, h3, h4, h5, h6 {
    color: black;  
  }

  

`;

export default GlobalStyle;
