// Survey.js
import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import ThankYou from './ThankYou'; // Add this import

import styled from 'styled-components';
import LogoutButton from './logoutButton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { GrFormNextLink } from "react-icons/gr";



import firebaseConfig from './firebaseConfig';

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

const Pagebody = styled.div`
    background-image: url('https://firebasestorage.googleapis.com/v0/b/encuesta-dondesiempre.appspot.com/o/img%2Fbg%2Fbackgroundblue1.jpg?alt=media&token=5573329f-6f82-4fe7-9e80-462fe9e80531');
    background-size: cover;
    background-position: top;
`


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  min-height: 100vh;
  width: 60%;
`;

const BlurContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 10px; // Add border radius for rounded corners
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  margin-bottom: 16px;
  width: 100%;

`

const ScrollableContainer = styled.div`
  max-height: 600px; // Set a maximum height for the container
  overflow-y: auto; // Enable vertical scrolling
  overflow-x: hidden;
  scrollbar-width: thin; // Set the width of the scrollbar (for Firefox)
  scrollbar-color: #fff #fff; // Set the color of the scrollbar (for Firefox)
  
  &::-webkit-scrollbar {
    width: 5px; // Set the width of the scrollbar (for Chrome, Safari, and Opera)
    scrollbar-color: #fff
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.01); // Set the color of the scrollbar thumb (for Chrome, Safari, and Opera)
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.01); // Set the color of the scrollbar track (for Chrome, Safari, and Opera)
  }
`;

const QuestionList = styled.ul`
  list-style: none;
  padding: 0;
  text-align: center;
`;

const QuestionItem = styled.li`
  margin-bottom: 20px;
`;

const StarContainer = styled.div`
  display: flex;
  justify-content: center;  // Center the stars horizontally
  font-size: 24px;
  margin-top: 8px;
`;

const Star = styled.div`
  margin: 0 4px;  // Add margin on both sides of each star for spacing
  cursor: pointer;
  color: ${({ filled }) => (filled ? 'black' : 'black')};
`;



const ResponseTextArea = styled.textarea` 
  font-family: 'Oswald', sans-serif;
  width: 80%;
  padding: 8px;
  margin-top: 8px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border: none;
  border-radius: 4px; /* Add rounded corners */
  font-size: 14px; /* Set the font size */
  text-align: center; /* Center the text */
  color: black;
  background-color: rgba(128, 128, 128, 0.1); // Adjust the alpha value for transparency
//   backdrop-filter: blur(20px); // Optional: Apply a blur effect to the background
  outline: 1px solid #ccc;

  resize: none;

  &:focus {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const SubmitButton = styled.button`
 background-color: black;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;

  &:hover {
    background-color: #818FB4;
  }

  @media (max-width: 600px) {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
`;



const GoogleSignInButton = styled.button`
  background-color: #4285f4;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background-color: #2a65c7;
  }

  @media (max-width: 600px) {
    font-size: 12px; /* Adjust font size for smaller screens */
  }
`;


const LogoImage = styled.img`
  user-select: none;
  width: 100%; /* Adjust the width as needed */
  max-width: 500px; /* Set a max-width to prevent stretching on larger screens */
  height: auto;
  display: block;
  margin: 0 auto;
`;

const RewardText = styled.div`
color: #b11110;
`



const Survey = () => {
    const [user] = useAuthState(auth);
    const [responses, setResponses] = useState({}); // Use an object to store responses for each question
    const [questions, setQuestions] = useState([]);
    const [submitted, setSubmitted] = useState(false); // Track whether the survey is submitted
    const [isLoading, setIsLoading] = useState(false);
    const [couponCode, setCouponCode] = useState(null); // Include couponCode state
    const navigate = useNavigate();


    useEffect(() => {
        if ((user && user.uid === "fOKnXrXBClQ868D1GfDZySYesp63") || (user && user.uid === "1kXcLkEL7GeIeLBbvdwRfO70Wuq2")) { // admin user
            navigate('/admin');
        } else if (user && user.uid === "bmis6iqMe2hVuD7Jh7adOcc2oFE3") { // employee 
            navigate('/verificar')
        }
    }, [user, navigate]);



    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const getQuestions = firebase.functions().httpsCallable('getQuestions');
                const result = await getQuestions();
                setQuestions(result.data.sort((a, b) => a.order - b.order));
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };
        const checkSurveyStatus = async () => {
            try {
                if (user) {
                    const surveySnapshot = await firestore.collection('surveys')
                        .where('user', '==', user.uid)
                        .limit(1)
                        .get();

                    if (!surveySnapshot.empty) {
                        setSubmitted(true); // Set submitted state to true if the user has submitted a survey
                        await displayCouponCode(); // Retrieve and display the coupon code
                    }
                }
            } catch (error) {
                console.error('Error checking survey status:', error);
            }
        };

        fetchQuestions();
        checkSurveyStatus();
    }, [firestore, user]);

    const handleLogout = () => {
        firebase.auth().signOut().then(() => {
            navigate('/'); // Redirect to home page after logout
        });
    };

    const handleStarClick = (questionId, rating) => {
        setResponses(prevResponses => ({
            ...prevResponses,
            [questionId]: rating,
        }));
    };


    const handleSubmit = async () => {
        setIsLoading(true); // Set loading to true
        if (user) {
            const areAllQuestionsAnswered = questions.every(question => responses.hasOwnProperty(question.id));

            if (areAllQuestionsAnswered) {
                const responsesArray = Object.keys(responses).map(questionId => ({
                    questionId,
                    response: responses[questionId],
                }));

                try {

                    // current timestamp
                    const timestamp = firebase.firestore.Timestamp.now();

                    // Call the cloud function directly to create survey and generate coupon
                    const createSurveyAndGenerateCoupon = firebase.functions().httpsCallable('createSurveyAndGenerateCoupon');

                    await createSurveyAndGenerateCoupon({
                        user: user.uid,
                        userEmail: user.email,
                        data: responsesArray,
                        timestamp: timestamp,

                    });

                    // Clear survey data
                    setResponses({});
                    await displayCouponCode(); // Retrieve and display the coupon code
                    setSubmitted(true); // Set the submitted state to true
                    setIsLoading(false); // Set loading to false after submission
                } catch (error) {
                    console.error('Error creating survey and generating coupon:', error);
                }
            } else {
                toast.error('Se te olvidan algunas preguntas', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        }
    };

    const handleResponseChange = (questionId, value) => {
        setResponses(prevResponses => ({
            ...prevResponses,
            [questionId]: value,
        }));
    };

    const displayCouponCode = async () => {
        try {
            const couponSnapshot = await firestore.collection('coupons').where('user', '==', user.uid).get();

            if (!couponSnapshot.empty) {
                const couponData = couponSnapshot.docs[0].data();
                setCouponCode(couponData.couponCode);
            }
        } catch (error) {
            console.error('Error fetching coupon code:', error);
        }
    };

    return (
        <Pagebody>
            <LogoImage src="https://firebasestorage.googleapis.com/v0/b/encuesta-dondesiempre.appspot.com/o/img%2Flogo%2FdsLogo.png?alt=media&token=f2a998e0-c312-4814-9be8-4d7739c15730" alt="DONDE SIEMPRE" />
            <Container>
                {user ? (

                    <BlurContainer>
                        {isLoading ? (
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <h2>Cargando algo sabroso...</h2>
                            </div>
                        ) : (
                            <>
                                {submitted ? (
                                    <ThankYou couponCode={couponCode} />
                                ) : (


                                    <ScrollableContainer>

                                        <QuestionList>
                                            {questions.map((question) => (
                                                <QuestionItem key={question.id}>
                                                    <div>
                                                        {question.text}
                                                    </div>
                                                    {question.type === 'scale' ? (
                                                        <div>
                                                            <StarContainer>
                                                                {[1, 2, 3, 4, 5].map((rating) => (
                                                                    <Star
                                                                        key={rating}
                                                                        filled={responses[question.id] >= rating}
                                                                        onClick={() => handleStarClick(question.id, rating)}
                                                                    >
                                                                        {responses[question.id] >= rating ? <FaStar /> : <FaRegStar />}
                                                                    </Star>
                                                                ))}
                                                            </StarContainer>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <ResponseTextArea
                                                                value={responses[question.id] || ''}
                                                                onChange={(e) => handleResponseChange(question.id, e.target.value)}
                                                            />
                                                        </div>
                                                    )}
                                                </QuestionItem>
                                            ))}
                                        </QuestionList>

                                    </ScrollableContainer>

                                )}


                            </>


                        )}


                        {!submitted && !isLoading && (
                            <SubmitButton onClick={handleSubmit}>Enviar Encuesta <GrFormNextLink size={14} /></SubmitButton>
                        )}


                    </BlurContainer>
                ) : (
                    <SignIn />
                )}




            </Container>

        </Pagebody>
    );
};

const SignIn = () => {
    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider);
    };

    return (
        <Container>
            <BlurContainer>
                <GoogleSignInButton onClick={signInWithGoogle}>Inicia sesión con Google </GoogleSignInButton>
                <h3 style={{ textAlign: 'center', marginTop: '16px' }}>¡CONTESTA Y GANA!</h3>
            </BlurContainer>
        </Container>
    );
};

export default Survey;
