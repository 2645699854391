// verificar.js
import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import firebaseConfig from './firebaseConfig';
import NavButton from './navButton';

const auth = firebase.auth();
const firestore = firebase.firestore();

const Pagebody = styled.div`
    background-image: url('https://firebasestorage.googleapis.com/v0/b/encuesta-dondesiempre.appspot.com/o/img%2Fbg%2Fbackgroundyellow.jpg?alt=media&token=3ce883c8-d6dd-4f35-9f09-eff9d55a9a52');
    background-size: cover;
    background-position: top;
`

const BlurContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5); // Adjust the alpha value for transparency
  padding: 5px;
  border-radius: 10px; // Add border radius for rounded corners
  backdrop-filter: blur(40px); // Optional: Apply a blur effect to the background
  -webkit-backdrop-filter: blur(30px);
  margin-bottom: 16px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 70%;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  width: 70%;
  min-height: 100vh;
  
`;

const VerifyCouponTitle = styled.h1`
  margin-bottom: 10px;
`;

const CouponInput = styled.input`
  width: 60%;
  padding: 8px;
  margin-top: 8px;
  text-align: center; /* Center text in the input field */
`;

const VerifyButton = styled.button`
  background-color: #4285f4;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 10px;
  width:60%;
  &:hover {
    background-color: #818FB4;
  }
`;

const LogoutButton = styled.button`
  background-color: #4285f4;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    background-color: #818FB4;
  }
`;

const LogoImage = styled.img`
  user-select: none;
  width: 100%; /* Adjust the width as needed */
  max-width: 500px; /* Set a max-width to prevent stretching on larger screens */
  height: auto;
  display: block;
  margin: 0 auto;
`;


const Verificar = () => {
  const [user] = useAuthState(auth);
  const [couponCode, setCouponCode] = useState('');
  const navigate = useNavigate();


  const handleLogout = () => {
    firebase.auth().signOut().then(() => {
      navigate('/'); // Redirect to home page after logout
    });
  };

  const handleVerifyCoupon = async () => {
    try {
      const couponsCollection = firebase.firestore().collection('coupons');
      const couponsSnapshot = await couponsCollection.get();

      const validCouponDoc = couponsSnapshot.docs.find((doc) => doc.data().couponCode === couponCode);

      if (validCouponDoc) {
        const couponData = validCouponDoc.data();
        const now = new Date();
        const expirationDate = couponData.expirationDate.toDate();

        if (now <= expirationDate) {
          // Coupon is valid and not expired
          toast.success('¡Cupón Verificado!', {
            position: toast.POSITION.TOP_CENTER,
          });

          await couponsCollection.doc(validCouponDoc.id).delete();
        } else {
          // Coupon is expired
          toast.error('¡Cupón expirado!', {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } else {
        toast.error('¡Código de cupón inválido!', {
          position: toast.POSITION.TOP_CENTER,
        });
      }


    } catch (error) {
      console.error('Error verifying coupon:', error);
    }
  };



  return (
    <Pagebody>
      <Container>
        <LogoImage src={'https://firebasestorage.googleapis.com/v0/b/encuesta-dondesiempre.appspot.com/o/img%2Flogo%2FdsLogo.png?alt=media&token=f2a998e0-c312-4814-9be8-4d7739c15730'} alt="DONDE SIEMPRE" />

        {(user && user.uid === "fOKnXrXBClQ868D1GfDZySYesp63") || (user && user.uid === "1kXcLkEL7GeIeLBbvdwRfO70Wuq2") || (user && user.uid === "kyhL2r2iiuRKLf1ttaEmCv61F6a2") ? ( // employee and admin
          // Check if the user is an admin (replace UID with your admin user IDs)
          <>
            <BlurContainer>
              <VerifyCouponTitle>Verificación</VerifyCouponTitle>
              <CouponInput
                type="text"
                placeholder="Código"
                maxLength="8"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <VerifyButton onClick={handleVerifyCoupon}>Verifica Cupón</VerifyButton>
            </BlurContainer>
            <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
          </>
        ) : (
          <SignIn />
        )}

      </Container>

    </Pagebody>
  );
};

const SignIn = () => {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

};

export default Verificar;
