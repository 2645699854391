// CommonButton.js
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NavButton = styled(Link)`
  background-color: #030759;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 8px;
  text-decoration: none;
  display: block;
  margin: auto;
  margin-top: 16px; 
  &:hover {
    background-color: #2a65c7;
  }
`;

export default NavButton;
