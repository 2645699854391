// firebaseConfig.js
const firebaseConfig = {
    apiKey: "AIzaSyAYv0D5k9vICwYr4QlpS68iZgwmHq7rdmw",
    authDomain: "www.dondesiempretepregunta.com",
    projectId: "encuesta-dondesiempre",
    storageBucket: "encuesta-dondesiempre.appspot.com",
    messagingSenderId: "625944498379",
    appId: "1:625944498379:web:ad1d920c903e9f32ee8b4e",
    measurementId: "G-NXE5RK5D5X"
};
export default firebaseConfig;
