// ThankYou.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px; /* Add padding for better spacing */
  margin: 0 auto;
  max-width: 100%;
`;

const SuccessMessage = styled.p`
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
`;


const CouponCode = styled.p`
  color: #2ecc71; // Green color
  font-size: 30px;
  text-align: center;
  margin-top: 10px;
`;

const ExpirationMessage = styled.p`
  font-size: 18px;
  text-align: center;
  margin-top: 5px;
`;

const RedDate = styled.span`
  color: #e74c3c; // Red color
`;

const ThankYou = ({ couponCode }) => {
  const [expirationDate, setExpirationDate] = useState(null);

  useEffect(() => {
    const fetchCouponCode = async () => {
      try {
        const user = firebase.auth().currentUser;
        if (user) {
          const couponSnapshot = await firebase.firestore().collection('coupons').where('user', '==', user.uid).get();

          if (!couponSnapshot.empty) {
            // Calculate remaining days
            const expirationDate = couponSnapshot.docs[0].data().expirationDate.toDate();
            setExpirationDate(expirationDate);
          }
        }
      } catch (error) {
        console.error('Error fetching coupon code:', error);
      }
    };

    const loadCouponInfo = async () => {
      await fetchCouponCode();
    };

    // Fetch coupon information on each render (including page refresh)
    loadCouponInfo();
  }, []); // Empty dependency array to fetch the coupon information only once



  // Format the expiration date as "dd/mm"
  const formattedExpirationDate = expirationDate
    ? expirationDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
    })
    : null;


  return (
    <Container>
      <SuccessMessage>¡Gracias por tus comentarios!</SuccessMessage>
      {couponCode && <CouponCode>{couponCode}</CouponCode>}
      {formattedExpirationDate && (
        <ExpirationMessage>
          Válido <RedDate>{formattedExpirationDate}</RedDate>
        </ExpirationMessage>

      )}
      <SuccessMessage>Vuelve pronto para volver a realizar la encuesta.</SuccessMessage>
    </Container>
  );
};

export default ThankYou;
